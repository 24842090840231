import axios from 'axios';
import {ciamConfig as config} from '../config';
import {loginResponse, getCodeRespose, accessTokenResponse} from '../types/ciam';

const axiosInstance = axios.create({
  baseURL: config.baseURL
});

export async function login(mobile: String, password: String) : Promise<loginResponse> {
  try {
    const res = await axiosInstance.post<loginResponse>("/iam/v1/realms/tsel/authenticate?authIndexType=service&authIndexValue=phoneLoginPassword",
    null,
    {
      headers: {
        'AM-PHONENUMBER': mobile,
        'AM-PASSWORD': password,
        'AM-CLIENTID': config.clientId,
      }
    });
    return res.data;
  } catch (err) {
    console.log(`login error with err: ${err}`);
    throw new Error('login error');
  }
}

export async function getCode(mobile: String, token: String): Promise<getCodeRespose> {
  const uri = encodeURI(`${config.apiGateway}/ciam/getCode/${mobile}/${token}`);

  try {
    const res = await axiosInstance.get(uri);
    return res.data || null;
  } catch (err) {
    console.log('Error getting code:', err);
  }
  return null;
}

export async function getAccessTokens(mobile: String, codeResponse: getCodeRespose): Promise<accessTokenResponse> {
  const uri = encodeURI(`/iam/v1/oauth2/realms/tsel/access_token?grant_type=authorization_code&redirect_uri=${config.redirectURI}&code=${codeResponse.code}&client_id=${config.clientId}&code_verifier=${codeResponse.verifier}`);
  try {
    const res = await axiosInstance.post<accessTokenResponse>(
      uri,
      null,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'ADD-IDENTIFIER': mobile,
        }
      }
      );
    return res.data;
  } catch(err) {
    console.log('Error getting access tokens:', err);
  }
  return null;
}

/* 
TODO, need to store the cookie from login in session somewhere otherwise
logout will fail
*/
export async function logout(mobile: String) {
  const uri = encodeURI(`/iam/v1/sessions?AM-PHONENUMBER=${mobile}&AM-MSISDN=${mobile}&_action=logout`);
  try {
    await axiosInstance.post<any>(
      uri,
      null,
      {
        headers: {
          'am-clientid': config.clientId,
          'Content-Type': 'application/json',
        }
      }
    );
    return;
  } catch(err) {
    console.log('Error logging out at CIAM with error:', err);
  }
  return;
}