export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || 'Hgl3E4c6fHKlvU1aF5SPGkO1MOFL9zT6',
  domain: process.env.REACT_APP_AUTH0_DOMAIN || 'lively.au.auth0.com'
};

export const ciamConfig = {
  apiGateway: process.env.REACT_APP_GETCODE_ENDPOINT || 'http://localhost:4000',
  clientId: process.env.REACT_APP_CIAM_CLIENT_ID || '7fd3ce635ab84fcb8ae910da3e541c8f',
  baseURL: process.env.REACT_APP_CIAM_BASE_URL || 'https://preprod.ciam.telkomsel.com:10001',
  redirectURI: process.env.REACT_APP_CIAM_REDIRECT_URI || 'https://api.stg.munalively.com/auth/callback',
}

export const endpointConfig = {
  apiGateway: process.env.REACT_APP_APIGW_ENDPOINT || 'https://api.stg.munalively.com/graphql',
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

/*
1fddbcbff36a4ec08b55ddb7b227d60c
*/